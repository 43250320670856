export const FEATURE_FLAGS_ENABLED = 'featureFlagsEnabled';
export const NEW_AGENT_EXPERIENCE = 'new_agent_experience';
export const UK_ENVIRONMENT_ENABLED = 'ukEnvironmentEnabled';
export const DYNAMIC_FORMS_ALL_PRODUCTS = 'dynamic_forms_all_products';

export const GROWTHBOOK_FEATURE_SETTINGS = {
  FEATURE_FLAGS_ENABLED,
  NEW_AGENT_EXPERIENCE,
  UK_ENVIRONMENT_ENABLED,
  DYNAMIC_FORMS_ALL_PRODUCTS,
} as const;

export type GrowthbookFeature =
  (typeof GROWTHBOOK_FEATURE_SETTINGS)[keyof typeof GROWTHBOOK_FEATURE_SETTINGS];

export const THEME_TYPES = {
  LIGHT: 'light',
  DARK: 'dark',
} as const;
