import { ProductTypes } from '../types';
import { getRegionByProduct } from '../i18n/utils';
import { deriveCurrencyFormat } from '../i18n/currencies';
import ProductStatics from '../console/_statics/product.statics.json';
import type { Languages } from '../i18n/i18n.language-config';

export const stringsToBools = (
  object: Record<string, unknown>,
  TRUE_STRING = 'yes',
  FALSE_STRING = 'no'
) => {
  return Object.keys(object).reduce((a, key) => {
    let value = object[key];

    if (value === TRUE_STRING) {
      value = true;
    }

    if (value === FALSE_STRING) {
      value = false;
    }

    return { ...a, [key]: value };
  }, {});
};

export const _isOneOf = (value: unknown, options: Array<unknown>) => {
  for (const element of options) {
    if (value === element) {
      return true;
    }
  }

  return false;
};

/**
 * Pretty Id
 * @param charProfile - 36 encompasses full alpha numeric character set (e.g. 10 would only be numbers)
 * @param prefix
 * @param suffix
 */
export const prettyId = (charProfile = 36, prefix = '', suffix = '') =>
  `${prefix}${Math.random().toString(charProfile).slice(2)}${suffix}`;

export const prepColumnFilters = (columns: any) =>
  columns.reduce(
    (acc: any, column: any) =>
      typeof column.filterType === 'string'
        ? {
            ...acc,
            [column.name.replaceAll('.', '-')]: {
              text: column.label || column.title,
              type: column.filterType,
              getListOptions: column.getListOptions,
            },
          }
        : acc,
    {}
  );

export const getFee = (row: any) => {
  let { brokerFee, mgaFee, additionalBrokerFee } = row;

  // Default to 0 if undefined
  additionalBrokerFee = additionalBrokerFee || 0;
  mgaFee = mgaFee || 0;
  brokerFee = brokerFee || 0;

  let totalFee = 0;

  if (row.product === ProductTypes.p100) {
    totalFee = brokerFee + additionalBrokerFee;
  } else {
    totalFee = mgaFee;
  }

  const language = getRegionByProduct(row.product);
  return totalFee ? deriveCurrencyFormat(language, totalFee) : '-';
};

export const getProductsByRegion = (region: Languages) => {
  return ProductStatics.PRODUCT_OPTIONS.filter((product) =>
    product.regions.includes(region)
  );
};

export const getDeepNestedValueByKey = (
  searchedObject: unknown,
  searchedKey: string
): Record<string, unknown> | undefined => {
  let respectiveValue;
  const hasKeyField = (obj: unknown, key: string): unknown => {
    if (!obj || typeof obj !== 'object') {
      return false; // If obj is null, undefined, or not an object, return false
    }

    if (key in obj) {
      respectiveValue = (obj as Record<string, unknown>)?.[key];
      return true; // If key field is present in obj, return true
    }

    return Object.values(obj).some((val) => hasKeyField(val, key));
  };

  hasKeyField(searchedObject, searchedKey);

  return respectiveValue;
};
