import { ServiceHandler, applyQueryConfig } from '../../utils';

import type { PageableResponse } from '../serializers';
import { standardQuerySerializer } from '../serializers';
import { SUBMISSIONS_QUERY_PARAM_MAPPING } from './submissions.param-mapping';

import type {
  CreateSubmissionPayload,
  SubmissionTableRow,
  ISubmissionMetricsResponse,
  SubmissionDetailsDto,
  SubmissionEligibleProductsResponse,
  SubmissionFirmographics,
} from './submissions.api.types';
import type { SelectOption } from '../../console/agencies/quotes/PrimeX/customizeQuote/reducer/types';

export const searchSubmissions = ServiceHandler<
  unknown,
  PageableResponse<SubmissionTableRow>
>({
  url: '/api/account/v1/submission/search',
  method: 'GET',
  paramsSerializer: (params) =>
    standardQuerySerializer(
      applyQueryConfig(SUBMISSIONS_QUERY_PARAM_MAPPING, params)
    ),
});

export const fetchSubmissionsMetrics = ServiceHandler<
  unknown,
  ISubmissionMetricsResponse
>({
  url: '/api/account/v1/submission/stats',
  method: 'GET',
});

/**
 * Utilized to create submission records within our platform.
 * Creates an account if one does not exist with the passed firmographic data.
 * Implicitly utilizes agency information from the user's JWT when that data is not passed within the request body.
 *
 * @date 2/26/2024 - 1:02:33 PM
 *
 * @type {*}
 */
export const createSubmission = ServiceHandler<
  CreateSubmissionPayload,
  unknown
>({
  url: '/api/account/v1/submission',
  method: 'POST',
});

export const fetchSubmissionsStatuses = ServiceHandler<
  unknown,
  SelectOption<string>[]
>({
  url: '/api/account/v1/submission/options/submission-status',
});

export const fetchOperationalStatuses = ServiceHandler<
  unknown,
  SelectOption<string>[]
>({
  url: '/api/account/v1/submission/options/operational-status',
});

export const bulkAssignAssignee = ServiceHandler({
  url: '/api/account/v1/submission/bulk-assign',
  method: 'PUT',
});

type GetSubmissionsUsersResponse = {
  firstName: string;
  lastName: string;
  id: string;
}[];

export const getSubmissionsUsers = ServiceHandler<
  unknown,
  GetSubmissionsUsersResponse
>({
  url: '/api/auth/v1/users/submissions',
});

type UpdateSubmissionDto = {
  assignee?: string;
  needBy?: string;
  operationalStatus?: string;
  firmographics?: Partial<SubmissionFirmographics>;
};

export const updateSubmission = ServiceHandler<UpdateSubmissionDto, unknown>({
  url: '/api/account/v1/submission/:submissionId',
  method: 'PUT',
});

export const getSubmissionById = ServiceHandler<unknown, SubmissionDetailsDto>({
  url: '/api/account/v1/submission/:id',
  method: 'GET',
});

export const getSubmissionByMsgId = ServiceHandler({
  url: '/api/account/v1/submission/msgId/:msgId',
});

export const getSubmissionEligibleProducts = ServiceHandler<
  unknown,
  SubmissionEligibleProductsResponse
>({
  url: '/api/account/v1/submission/eligible-products/:id',
});
