import { transformToOption } from '../../utils/functional/fp';

import type { AccountStatus, BoolAsString } from '.';
import type { Countries, Languages } from '../../i18n/i18n.language-config';
import type { AgencyDto } from '../agencies/dto/agency.dto';
import type { USState } from '../common/misc';
import type { ProductType } from '../products';
import type { ClaimStatus } from '../claims/claim.dto';

interface RiskFactorsDetails {
  company: number;
  industry: number;
  max: number;
  min: number;
}

export interface RiskFactorsDto {
  companyAggregate: number;
  industryAggregate: number;
  factors: {
    cloudSecurity: number;
    compliance: number;
    cyberExtortion: number;
    darkIntelligence: number;
    endpointSecurity: number;
    fundsTransfer: number;
    insiderThreat?: number;
    networkSecurity: number;
    supplyChain?: number;
  };
  details?: {
    cloudSecurity: RiskFactorsDetails;
    company: RiskFactorsDetails;
    compliance: RiskFactorsDetails;
    cyberExtortion: RiskFactorsDetails;
    darkIntelligence: RiskFactorsDetails;
    endpointSecurity: RiskFactorsDetails;
    fundsTransfer: RiskFactorsDetails;
    insiderThreat: RiskFactorsDetails;
    networkSecurity: RiskFactorsDetails;
    supplyChain: RiskFactorsDetails;
  };
}

export interface Domain {
  domain: string;
}

export interface AccountDto {
  id: string;
  isOpenForRenewal?: boolean;
  changeInScope?: boolean;
  changeInScopeDetail?: string;
  agencies: Partial<AgencyDto>[];
  agencyId: string; // agencyId will be assigned at the top-level of the account whenever an agency is hardlinked
  agencyName?: string;
  agentFirstName?: string;
  agentLastName?: string;
  agentEmail?: string;
  agentPhone?: string;
  eligibleProducts: ProductType[];
  additionalNamedInsured?: string[];
  primaryIndustry: string;
  secondPrimaryIndustry?: string;
  description?: string;
  naicsCode: number;
  sicCode?: string;
  secondNaicsCode?: number;
  otherRevenue?: number;
  revenue: number;
  nonUsRevenue: number;
  totalRevenue: number;
  coverages100: number[];
  coverages250: number[];
  accountValid: boolean;
  excludedFromSE: boolean;
  capped: boolean;
  cbid: string;
  profileScore: number;
  policyId?: string;
  policyExpiryDate?: string;
  domainName?: string;
  domains?: Domain[];
  url?: string;
  noOtherDomains: boolean;
  hostname: string;
  name: string;
  ownershipType: string;
  entityType: string;
  dbaOrTradestyle?: string;
  noOfEmployeesAll: number;
  isFranchise: boolean;
  franchiseSystemConnectivity?: boolean;
  yearEstablished: number;
  secondaryIndustry: string;
  secondSecondaryIndustry?: string;
  primaryNaicsPercentage: number;
  secondaryNaicsPercentage?: string;
  address1: string;
  address2?: string;
  address3?: string;
  city: string;
  state: USState;
  zipCode: string;
  country: Countries;
  region: Languages;
  phoneNumber: string;
  created: string;
  modified: string;
  ein?: number;
  dunsNumber: string;
  isNonStandardDomain: boolean;
  isManufacturing: boolean;
  isHeadquarters: boolean;
  location?: {
    lat: number;
    lon: number;
  };
  locationAlias?: string;
  status: AccountStatus;
  productType: ProductType;
  isFavorite: boolean;
  isVerifiedByAgent: boolean;
  verifiedDate?: Date;
  quickQuotePremium: number;
  percentInternationalSales: number;
  numberOfConnectors: number;
  numberOfPersonalRecords?: number;
  surplusOnly: boolean;
  surplusAvailable: boolean;
  isPaperApp: boolean;
  isOtherAppAcceptable: boolean;
  industryClass: number;
  quotingDba?: string;
  errorMessage?: string;

  group1Verified: boolean;
  group2Verified: boolean;
  group3Verified: boolean;

  // questions
  // * this needs some refactoring -- we have mixed our DTO type with the type of our transformed/derived record
  // * BoolAsString is from our transformer.. The correct type here is boolean as this is a DTO type 👆
  // todo: Create a separate type for the transformed record. Remove BoolAsString from the DTO type
  isVerifyingBankAccounts?: BoolAsString | boolean;
  incidentResponsePlan?: boolean;
  isAuthenticatingFundTransferRequests?: BoolAsString | boolean;
  isPreventingUnauthorizedWireTransfers?: BoolAsString | boolean;
  isSecurityOfficer?: BoolAsString | boolean;
  isSecurityTraining?: BoolAsString | boolean;
  useEncryption?: BoolAsString | boolean;
  useCloudStorage?: BoolAsString | boolean;
  claimHistory?: number;
  claimStatus?: ClaimStatus;
  mfaAuthentication?: BoolAsString | boolean;
  mfaEmail?: BoolAsString | boolean;
  mfaCloudDeployments?: BoolAsString | boolean;
  mfaMissionCriticalSystems?: BoolAsString | boolean;
  mfaRemoteAccess?: BoolAsString | boolean;
  priorCoverage?: string;

  p250Details?: {
    dmzSeparation?: BoolAsString | boolean;
    thirdPartySecurityAgreement?: BoolAsString | boolean;
    testedFullFailover?: BoolAsString | boolean;
    civilOrCriminalAction?: BoolAsString | boolean;
    lossInBusinessIncome?: BoolAsString | boolean;
    pastCyberIncident?: BoolAsString | boolean;
    pendingLitigation?: BoolAsString | boolean;
    securityBreachRequiringNotification?: BoolAsString | boolean;

    backupFrequency?: string;
    patchingFrequency?: string;
    defaultAggLimit?: number;
    uwReferral?: boolean;
  };

  p100ProQuestions?: {
    //TODO: fill in all questions and type them
    [key: string]: any;
  };
  primeXQuestions?: {
    //TODO: fill in all questions and type them
    [key: string]: any;
  };
  securityContact?: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    title: string;
    company: string;
  };
  // organization contact
  policyContactFirstName?: string;
  policyContactLastName?: string;
  policyContactEmail?: string;
  policyContactPhone?: string;
  securityAnswers?: any[];
  contactPhoneCountryCode?: string;

  //Spotlight
  spotlightIds?: string[];

  // From RE Inbox response
  riskMgmtDetails?: {
    callReason?: string;
    callReasonDescription?: string;
    origin?: string;
    requestedBy?: string;
    riskMgmtDeadline?: string;
    riskMgmtPriority?: {
      name: string;
      value: number;
    };
  };
  industryCode?: string | number;
  sicPrimaryDesc?: string;
  sicSecondaryDesc?: string;
  isCrownDependency?: boolean;
  collectingPersonalInformation: boolean;

  //UI properties
  ui_primaryIndustryDescription?: string;
  ui_secondaryIndustryDescription?: string;
}

export type BrokerDashboardClientListItemDTO = Record<string, any> & {
  policyCount: number;
  agentEmail: string;
};

export enum EntityType {
  Independent = 'Independent',
  Holding = 'Holding',
  Subsidiary = 'Subsidiary',
  Branch = 'Branch',
  Parent = 'Parent',
}

export const ENTITY_TYPE_OPTIONS = Object.freeze(
  Object.values(EntityType).map(transformToOption())
);
